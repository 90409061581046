import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

import { Layout } from "../components/Layout";

function Contact() {
  const [formContents, setFormContents] = useState({
    FIRSTNAME: "",
    LASTNAME: "",
    EMAIL: "",
    REASON: "",
    MESSAGE: "",
  });
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmissionResponse, setFormSubmissionResponse] = useState({});

  const submitContactForm = (e) => {
    e.preventDefault();
    addToMailchimp(formContents.EMAIL, formContents) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        setFormSubmissionResponse(data);
        if (data.result === "success") {
          setFormSubmitSuccess(true);
        } else {
          setFormSubmitSuccess(false);
        }
      })
      .catch(() => {
        setFormSubmitSuccess(false);
      });
  };

  const updateContents = (field, value) => {
    setFormContents({ ...formContents, [field]: value });
  };

  return (
    <Layout
      metaTitle="contact"
      metaDescription="Get in touch with the Schengen Simple team"
    >
      <section className="bg-teal-alpha-30 py-20 antialiased">
        <div className="container">
          {formSubmitSuccess ? (
            <>
              <h1 className="text-center text-3xl lg:text-4xl xl:text-5xl font-medium mb-10 md:mb-16 lg:mb-24">
                Thanks for reaching out!
              </h1>
              <p className="mb-10">We'll get back to you as soon as we can.</p>
            </>
          ) : (
            <>
              <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium mb-10 md:mb-16 lg:mb-24">
                Get in touch
              </h1>
              <p className="mb-10">
                Want to share some feedback on the app, or have a feature
                request? We'd love to hear from you!
              </p>
              <div id="mc_embed_signup">
                <form
                  onSubmit={(e) => submitContactForm(e)}
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate flex flex-col md:w-7/12"
                  target="_self"
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="flex gap-10">
                      <div className="mc-field-group ">
                        <label
                          className="text-sm font-medium text-white"
                          htmlFor="mce-FIRSTNAME"
                        >
                          Your first name <span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          value={formContents.FIRSTNAME}
                          onChange={(e) =>
                            updateContents("FIRSTNAME", e.target.value)
                          }
                          name="FIRSTNAME"
                          className="form-input mb-4 mt-2 text-charcoal bg-white
                    block w-full px-4
                    border-0 border-b-2 border-white
                    focus:ring-0 focus:border-teal"
                          id="mce-FIRSTNAME"
                        />
                        <span
                          id="mce-FIRSTNAME-HELPERTEXT"
                          className="helper_text"
                        ></span>
                      </div>

                      <div className="mc-field-group">
                        <label
                          className="text-sm font-medium text-white"
                          htmlFor="mce-LASTNAME"
                        >
                          Your last name
                        </label>
                        <input
                          type="text"
                          value={formContents.LASTNAME}
                          onChange={(e) =>
                            updateContents("LASTNAME", e.target.value)
                          }
                          name="LASTNAME"
                          className="form-input mb-4 mt-2 text-charcoal bg-white
                    block w-full px-4
                    border-0 border-b-2 border-white
                    focus:ring-0 focus:border-teal"
                          id="mce-LASTNAME"
                        />
                        <span
                          id="mce-LASTNAME-HELPERTEXT"
                          className="helper_text"
                        ></span>
                      </div>
                    </div>

                    <div className="mc-field-group">
                      <label
                        className="text-sm font-medium text-white"
                        htmlFor="mce-EMAIL"
                      >
                        Email Address <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        value={formContents.EMAIL}
                        onChange={(e) =>
                          updateContents("EMAIL", e.target.value)
                        }
                        name="EMAIL"
                        className="form-input mb-4 mt-2 text-charcoal bg-white block w-full px-4
                    border-0 border-b-2 border-white
                    focus:ring-0 focus:border-teal required email"
                        id="mce-EMAIL"
                        required
                      />
                      <span
                        id="mce-EMAIL-HELPERTEXT"
                        className="helper_text"
                      ></span>
                    </div>
                    <div className="mc-field-group">
                      <label
                        className="text-sm font-medium text-white"
                        htmlFor="mce-REASON"
                      >
                        What brings you here today?
                      </label>
                      <select
                        value={formContents.REASON}
                        onChange={(e) =>
                          updateContents("REASON", e.target.value)
                        }
                        name="REASON"
                        className="form-select mb-4 mt-2 text-charcoal bg-white block w-full px-4
                    border-0 border-b-2 border-white
                    focus:ring-0 focus:border-teal"
                        id="mce-REASON"
                      >
                        <option value=""></option>
                        <option value="Share feedback">
                          I have feedback to share
                        </option>
                        <option value="Feature request">
                          I have a feature request
                        </option>
                        <option value="Report a bug">
                          I'd like to report a bug
                        </option>
                        <option value="General enquiry">
                          I have a general enquiry
                        </option>
                        <option value="Data deletion">
                          I'd like to request my data is deleted
                        </option>
                        <option value="Account deletion">
                          I'd like to request my account is deleted
                        </option>
                      </select>
                      <span
                        id="mce-REASON-HELPERTEXT"
                        className="helper_text"
                      ></span>
                    </div>
                    <div className="mc-field-group">
                      <label
                        className="text-sm font-medium text-white"
                        htmlFor="mce-MESSAGE"
                      >
                        Message{" "}
                      </label>
                      <textarea
                        value={formContents.MESSAGE}
                        onChange={(e) =>
                          updateContents("MESSAGE", e.target.value)
                        }
                        name="MESSAGE"
                        className="form-textarea mb-4 mt-2  bg-white block w-full px-4
                    border-0 border-b-2 border-white
                    focus:ring-0 focus:border-teal"
                        id="mce-MESSAGE"
                        cols="30"
                        rows="10"
                      ></textarea>
                      <span
                        id="mce-MESSAGE-HELPERTEXT"
                        className="helper_text"
                      ></span>
                    </div>
                    <div id="mce-responses" className="clear foot">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_3987a4b2d5c75ad9eea737933_027f3c3bc3"
                        tabIndex="-1"
                        value=""
                        onChange={() => {}}
                      />
                    </div>
                    <div className="optionalParent">
                      <div className="clear foot">
                        <button
                          type="submit"
                          name="Send message"
                          id="mc-embedded-subscribe"
                          className="button cursor-pointer text-white w-full rounded-sm bg-teal-light hover:bg-teal p-3"
                        >
                          Send message
                        </button>
                        {formSubmissionResponse.result === "error" && (
                          <p className="text-error font-bold my-6 ">
                            {formSubmissionResponse.msg}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default Contact;
